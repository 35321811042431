"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useAccount = (props) => {
    const [account, setAccount] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const getAccount = () => {
        if (isLoading)
            return;
        setIsLoading(true);
        props.useHotSpiceApi.getAccount().then((resp) => {
            const data = resp.data;
            setAccount(data);
            setIsLoading(false);
        });
    };
    const saveAccount = (account) => {
        setIsLoading(true);
        props.useHotSpiceApi
            .saveAccount(account)
            .then((resp) => {
            const data = resp.data;
            setAccount(data);
            setIsLoading(false);
        })
            .catch((e) => {
            console.error('Error saving account', e);
            props.useNotifications.error('Error saving account', 'Please try again later');
            setAccount(account);
        });
    };
    return {
        account,
        getAccount,
        saveAccount,
        isLoading
    };
};
exports.default = useAccount;
